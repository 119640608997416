// ◆ 動作_對象 (動詞_名詞) ADD_TODO , DELETE_TODO , UPDATE_TODO
// ◆ 功能/區域_動作_對象 (名詞_動詞_名詞) CART_ADD_ITEM , CART_DELETE_ITEM , PRODUCT_ADD_ITEM
// ◆ 動作_名詞_狀態 (動詞_名詞_形容詞/動詞) FETCH_USER_REQUEST , FETCH_USER_SUCCESS , FETCH_USER_FAIL , FETCH_USER_COMPLETE

//  window - action
export const SCREEN_RESIZE = 'SCREEN_RESIZE'
export const SCREEN_SCROLL = 'SCREEN_SCROLL'
export const SET_ACTIVE_BREAKPOINT = 'SET_ACTIVE_BREAKPOINT'

//  component - action
//// web

//// mobile
export const MOBILE_SHOW_NAVS = 'MOBILE_SHOW_NAVS'
