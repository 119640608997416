import React, { useEffect } from 'react'
// Share Component
import DivideLine from '../components/share/DivideLine'
import Information from '../components/main/contact/Information'
import Question from '../components/main/contact/Question'
import MobileTitle from '../components/share/MobileTitle'

// Contact Data

const contact = [
  {
    icon: '/assets/icon/email.svg',
    contents: 'walkwalkah@gmail.com',
    alt: 'email',
    href: 'mailto:walkwalkah@gmail.com',
  },
  {
    icon: '/assets/icon/phone.svg',
    contents: '( 04 ) 2236 - 8936',
    alt: 'phone',
    href: 'tel:( 04 ) 2236 - 8936',
  },
  {
    icon: '/assets/icon/marker.svg',
    contents: '40651 台中市北屯區文心路四段776號',
    alt: 'location',
    href: 'https://goo.gl/maps/XqaPeRuyJnThSQKm7',
  },
  {
    icon: '/assets/icon/clock.svg',
    contents: '早上 10:30 - 12:30、下午 13:30 - 17:00',
    alt: 'time',
    href: '/time',
  },
  {
    icon: '/assets/icon/clock.svg',
    contents: '晚上 18:00 - 21:00',
    alt: 'time',
    href: '/time',
  },
  {
    icon: '/assets/icon/facebook.svg',
    contents: '沃可動物醫院',
    alt: 'facebook',
    href: 'https://www.facebook.com/walkvet/',
  },
]
// parking Data
const parking = [
  { title: '熱河路停車場', href: 'https://goo.gl/maps/LEDjW7wn1W4a3V2t6' },
  { title: '24H收費停車場', href: 'https://goo.gl/maps/yLeJF1Y1jfDyP4nh6' },
  {
    title: '崇德北平自動收費停車場',
    href: 'https://goo.gl/maps/mH9V7EZdJXmAP2zV9',
  },
  { title: '北平路四段停車場', href: 'https://goo.gl/maps/qpW6evWmQzNE1yhf8' },
]
const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
    return () => {}
  }, [])
  return (
    <div className="pageBuffering">
      <MobileTitle text={'聯繫我們'} className={'mt-5'} />
      <div className="contact">
        <Information contact={contact} parking={parking} />
        <DivideLine text={'詢問我們'} style={{ width: '92vw' }} />
        <Question />
      </div>
    </div>
  )
}
export default Contact
