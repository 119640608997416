import React from 'react'

const Alert = props => {
  const { setState, state, data, success, fail, formFetch } = props
  const { setWalkAlert, setSuccess, setFail, setBlank } = setState

  const onClickCancel = e => {
    e.stopPropagation()
    setWalkAlert(false)
    setSuccess(false)
  }
  const onClickNoCancel = e => {
    e.stopPropagation()
    // setSuccess(false)
    // setState(false)
  }
  const onClickSubmit = e => {
    e.stopPropagation()
    formFetch()
  }
  const onClickSuccess = async e => {
    e.stopPropagation()
    await setBlank()
    await setWalkAlert(false)
    await setSuccess(false)
    await setFail(false)
  }
  return (
    <div
      onClick={onClickCancel}
      className="walkAlert containerDisplaySlow position-fixed d-flex justify-content-center align-items-center"
    >
      <div
        onClick={onClickNoCancel}
        className={'bg-white alertBox text-center ' + (state ? ' active' : '')}
      >
        <div className="alertContents pt-4" >
          <img  src="assets/logo/alert.svg" alt="" />
          {!success && !fail ? (
            <>
              <p className="contentsColorGrey mt-3">請檢查個人資料是否正確</p>
              <p><p className="contentsColorGrey d-inline-block mb-0">電話：</p>{data.phone} </p>
              <p><p className="contentsColorGrey d-inline-block mb-0">Email：</p>{data.email} </p>
            </>
          ) : (
            <>
              <h2
                className={
                  'containerDisplaySlow ' +
                  (fail ? 'contentsColorDanger' : ' contentsColorPrimary')
                }
                style={{ marginTop: '30px' }}
              >
                {fail ? '送出失敗' : '送出成功'}
              </h2>
              <br />
              {fail ? (
                <small className="contentsColorGrey">
                  網頁異常，我們會盡快檢修，欲詢問請致電沃可
                </small>
              ) : (
                  <p className="contentsColorGrey">感謝您的詢問，我們會盡快回覆</p>
              )}
            </>
          )}
        </div>
        <div
          className={
            'alertButton d-flex  text-center ' +
            (!success && !fail
              ? 'justify-content-around'
              : 'justify-content-center')
          }
        >
          <div
            onClick={!success && !fail ? onClickSubmit : onClickSuccess}
            className={
              'buttonConfirm d-flex justify-content-center align-items-center ' +
              (!success && !fail
                ? ' contentsColorPrimary containerBorderPrimary '
                : ' contentsColorGrey containerBorderGrey ')
            }
          >
            <h5 className="mb-0">{!success && !fail ? '確定' : '離開'}</h5>
          </div>

          <div
            className={
              'buttonModify containerBorderDanger contentsColorDanger d-flex justify-content-center align-items-center ' +
              (!success && !fail ? '' : ' active')
            }
          >
            {!success && !fail ? (
              <h5 className="mb-0" onClick={onClickCancel}>
                修改
              </h5>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Alert
