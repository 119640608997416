import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

//Import Pages
import Home from '../pages/Home'
import About from '../pages/About'
import Team from '../pages/Team'
import Service from '../pages/Service'
import Contact from '../pages/Contact'
import Time from '../pages/Time'

import Header from '../components/share/Header'
import Footer from '../components/share/Footer'

const WalkAnimalRoute = () => (
  <Router>
    <Header />
    <Switch>
      <Route exact path={'/'} component={Home} />
      <Route path={'/about'} component={About} />
      <Route path={'/team'} component={Team} />
      <Route path={'/service'} component={Service} />
      <Route path={'/time/:vetName?'} component={Time} />
      <Route path={'/contact'} component={Contact} />
      <Route component={Home} />
    </Switch>
    <Footer />
  </Router>
)
const mapStateToProps = store => ({ store: store })
export default connect(mapStateToProps)(WalkAnimalRoute)
