import { combineReducers } from 'redux'

import { SCREEN_RESIZE, SCREEN_SCROLL, SET_ACTIVE_BREAKPOINT } from '../actions'

// initial
//// Component
const initialComponentUiState = {
  mobile: {},
  web: {},
}
//// Window
const initialWindowUiState = {
  screenResize: typeof window === 'object' ? window.innerWidth : null,
  screenScroll: typeof window === 'object' ? window.scrollY : null,
  screenBreakpoint:
    typeof window === 'object' ? { name: 'default', size: null } : null,
}

// reducer
//// Component
const componentUi = (state = initialComponentUiState, action) => {
  switch (action.type) {
    case 'test':
      return { ...state, test: action.payload }
    default:
      return state
  }
}
//// Window
const windowUi = (state = initialWindowUiState, action) => {
  switch (action.type) {
    case SCREEN_SCROLL:
      return { ...state, screenScroll: action.payload }
    case SCREEN_RESIZE:
      return { ...state, screenResize: action.payload }
    case SET_ACTIVE_BREAKPOINT:
      return {
        ...state,
        screenBreakpoint: {
          name: action.breakpointName,
          size: action.breakpointSize,
        },
      }
    default:
      return state
  }
}

// combine
export default combineReducers({
  windowUi: windowUi,
  componentUi: componentUi,
})
