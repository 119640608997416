import { combineReducers } from 'redux'
// reducer
import Ui from './ui'
import domainData from './domainData'
import appState from './appState'
// combine
export default combineReducers({
  domainData: domainData,
  appState: appState,
  ui: Ui,
})
