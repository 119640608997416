import React, { useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { breakpointIsGreaterThan } from '../../window/mediaQueries/Helper'
import { Helmet } from 'react-helmet'

const nav = [
  //{ name: '相關消息', route: 'news' },
  { name: '關於沃可', route: 'about' },
  { name: '醫療團隊', route: 'team' },
  { name: '診療服務', route: 'service' },
  { name: '門診班表', route: 'time' },
  { name: '聯繫我們', route: 'contact' },
]
const helmetDescription = {
  home:
    '我們是一群來自中興大學獸醫教學醫院的團隊，過去幾年我們經歷完整的臨床訓練，並在各自治療領域上盡心盡力。',
  about:
    '這個夢想的起點自北台中文心路四段開始，從北屯深耕，未來將致力發展成地區第一線的綜合醫療中心，不論獸醫師或獸醫助理，都將發揮所長、互助與支援，創造一個對動物友善而且尊重動物的醫療環境，並盡其所能去治療、照護需要的犬貓病患，讓這些小生命可以自由地踏出每一步、陪伴我們，直至終老。',
  team:
    '這個團隊集結了擁有內科、外科、牙科、腫瘤科、影像科的專業醫師，擁有精密的檢查設備與紮實的臨床照護訓練。',
  service:
    '我們承諾提供優質、完善且多元的醫療服務，並推廣於寵物預防醫學，除了各科疾病診察、急重症照護、健康檢查、疫苗接種、牙齒衛生等，亦提供犬貓居家照護觀念、各種衛生教育與其他健康防護措施等相關資訊。',
  time:
    '每日都有診，門診時間：早上 10:30 - 12:30、下午 13:30 - 17:00、晚上 18:00 - 21:00，歡迎來電預約 04-22368936。',
  contact:
    '預約專線 ☎️ 04-22368936，台中市北屯區文心路四段776號，🅿️ 附設停車場需自文昌三街進入哦，營業時間：上午10:30至晚間21:00，休息時間：中午12:30至下午13:30與下午17:00至晚間18:00。',
}
const helmetTitle = {
  home: '沃可動物醫院 Walk Animal Hospital',
  about: '關於沃可 About Walk',
  team: '醫療團隊 Team Walk',
  service: '診療服務 Service',
  time: '門診時間 Appointment',
  contact: '聯絡我們 Contact Us',
}

const Header = props => {
  const [menuCollapse, setMenuCollapse] = useState(false)
  const path = props.location.pathname
  const pathname = path.split('/')[1]
  const { size } = props
  const pathnameH = props.location.pathname.slice(1)
  return (
    <>
      {pathnameH === '' ? (
        <Helmet>
          <title>{helmetTitle['home']}</title>
          <meta name="description" content={helmetDescription['home']} />
          <meta property="og:title" content={helmetTitle['home']} />
          <meta property="og:description" content={helmetDescription['home']} />
          <meta property="og:image" content="assets/logo/logo192Green.png" />
        </Helmet>
      ) : (
        <Helmet>
          <title>{helmetTitle[pathnameH]}</title>
          <meta name="description" content={helmetDescription[pathnameH]} />
          <meta property="og:title" content={helmetTitle[pathnameH]} />
          <meta
            property="og:description"
            content={helmetDescription[pathnameH]}
          />
          <meta property="og:image" content="assets/logo/logo192Green.png" />
        </Helmet>
      )}

      <div className="header d-flex justify-content-between px-0 px-md-5 position-fixed containerColorPrimary">
        <div
          className=" align-self-center col-md-5 col-lg-5 col-xl-5 px-0 headerImgBox"
          style={{ zIndex: '14' }}
          onClick={() => setMenuCollapse(false)}
        >
          <NavLink to={'/'}>
            <img
              className="headerImg"
              src="/assets/icon/Logo2.svg"
              alt="Walk Animal Hospital Logo"
            />
          </NavLink>
        </div>
        {breakpointIsGreaterThan(769, size) ? (
          <nav
            className="navLink d-flex justify-content-around col-1  col-md-7 col-lg-6 col-xl-6 px-0"
            style={{ zIndex: '15' }}
          >
            {nav.map((value, index) => (
              <NavLink
                to={'/' + value.route}
                key={value + index}
                className="align-self-center text-white position-relative"
              >
                <p className="p5 m-0">{value.name}</p>
                {pathname === value.route ? (
                  <div className="navLinkBar bg-white position-absolute containerDisplaySlow"></div>
                ) : (
                  <></>
                )}
              </NavLink>
            ))}
          </nav>
        ) : (
          <>
            <div
              className="collapseButton position-absolute d-flex flex-column align-items-center justify-content-center "
              onClick={() => setMenuCollapse(!menuCollapse)}
            >
              <div
                className={
                  (menuCollapse ? 'active' : '') +
                  ' collapseBar collapseBar1 bg-white'
                }
              ></div>
              <div
                className={
                  (menuCollapse ? 'active' : '') +
                  ' collapseBar collapseBar2 bg-white'
                }
              ></div>
              <div
                className={
                  (menuCollapse ? 'active' : '') +
                  ' collapseBar collapseBar3 bg-white'
                }
              ></div>
            </div>

            <div className="mobilePhone position-absolute d-flex  justify-content-center align-items-center ">
              <a href="tel:( 04 ) 2236 - 8936">
                <img src="/assets/icon/phoneWhite.svg" alt="phoneIcon" />
              </a>
            </div>

            <div
              className={
                (menuCollapse ? 'active ' : '') +
                'mobileNav containerColorPrimary position-absolute'
              }
            >
              <div className="mobileNavBar containerColorPrimary position-absolute d-flex flex-column ">
                <div
                  className="containerColorLightGrey ml-auto"
                  style={{ width: '100vw', height: '1px' }}
                ></div>
                {nav.map((value, index) => (
                  <React.Fragment key={value + index}>
                    <NavLink
                      onClick={() => setMenuCollapse(false)}
                      to={'/' + value.route}
                      className={
                        'align-self-center my-4 my-sm-5 ' +
                        (pathname === value.route
                          ? 'contentsColorLightGrey'
                          : ' contentsColorWhite')
                      }
                    >
                      <p className="h5 m-0">{value.name}</p>
                    </NavLink>
                    <div
                      className="containerColorLightGrey mx-auto"
                      style={{ width: '70vw', height: '1px' }}
                    ></div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="headerBox"></div>
    </>
  )
}
const mapStateToProps = store => ({
  size: store.ui.windowUi.screenBreakpoint.size,
})
export default withRouter(connect(mapStateToProps)(Header))
