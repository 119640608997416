import React, { useEffect } from 'react'

// Component
import Carousel from '../components/main/about/Carousel'
import MobileTitle from '../components/share/MobileTitle'

// contents
const contents = [
  {
    title: '我們是來自中興大學獸醫教學醫院的團隊',
    contents:
      '過去幾年我們經歷完整的臨床訓練，並在各自的治療領域上盡心盡力。想要提升犬貓醫療品質的想法日漸強烈，這樣的信念是沃可動物醫院成立的起始點。\n在治療每位病患的過程中，我們發覺不論是毛小孩或浪浪，散步之於他們都是非常放鬆舒服的活動，因此讓每位犬貓病患經過治療後可以跟以前一樣探索世界、陪伴飼主散步、在草地上開心地跑跳翻滾，是我們成立沃可的初衷。',
  },
  {
    title: '我們正在開始實現一個夢想',
    contents:
      '這個團隊集結了擁有內科、外科、牙科、腫瘤科、影像科的專業醫師，擁有精密的檢查設備與紮實的臨床照護訓練，我們承諾提供優質且全面的醫療服務，並投入資源於寵物預防醫學，除了各科疾病診察、急重症照護、健康檢查、疫苗接種、牙齒衛生等，亦提供犬貓居家照護觀念、各種衛生教育與其他健康防護措施等相關資訊。',
  },
  {
    title: '這個夢想的起點自北台中文心路四段開始',
    contents:
      '從北屯深耕，未來將致力發展成地區第一線的綜合醫療中心，不論獸醫師或獸醫助理，都將發揮所長、互助並支援，盡其所能去治療、照護需要的犬貓病患，讓這些小生命可以自由地踏出每一步、陪伴我們，直至終老。',
  },
]

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
    return () => {}
  }, [])
  return (
    <div className="pageBuffering d-flex justify-content-center about flex-wrap px-xl-5">
      <div className="col-12 col-xl-6 px-0 pr-xl-4 mb-xl-5">
        <Carousel />
      </div>
      <MobileTitle
        text={'關於沃可'}
        className={'mt-4'}
        style={{ width: '100vw' }}
      />
      <div className="aboutContents col-12 col-xl-6 px-4 px-sm-5 pl-xl-4">
        {contents.map((value, index) => (
          <div className="mb-5" key={value + index}>
            <h4 className="contentsColorPrimary mb-3">{value.title}</h4>
            <p className="contentsColorGrey p5 " style={{ lineHeight: '1.8' }}>
              {value.contents}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default About
