import React from 'react'

const MobileTitle = props => {
  const { style, className, renderText } = props
  return (
    <div className={'mobileTitle text-center mt-1 ' + className} style={style}>
      {renderText?.() || <h4>{props.text}</h4>}
    </div>
  )
}

export default MobileTitle
