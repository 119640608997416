import { combineReducers } from 'redux'

// initial
//// domain Data
const initialState = {
  home: {},
  news: {},
}

// reducer
//// home
const home = (state = initialState.home, action) => {
  switch (action.type) {
    case 'test':
      return { ...state, test: action.payload }
    default:
      return state
  }
}
//// news
const news = (state = initialState.news, action) => {
  switch (action.type) {
    case 'test':
      return { ...state, test: action.payload }
    default:
      return state
  }
}

export default combineReducers({
  home: home,
  news: news,
})
