import React from 'react'
import { NavLink } from 'react-router-dom'

const Information = props => (
  <div className="information d-flex flex-wrap justify-content-around px-3 px-sm-4 px-lg-5">
    <iframe
      className="informationMap col-12 col-md-7 p-1 px-sm-3 pr-md-4 pr-lg-5"
      title="Walk Animal Hospital from Google Maps"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4829.854448200815!2d120.68732494877446!3d24.171630473075837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a7f97d0f557f64f!2z5rKD5Y-v5YuV54mp6Yar6Zmi!5e0!3m2!1szh-TW!2stw!4v1584256241746!5m2!1szh-TW!2stw"
      frameBorder="0"
      style={{ border: '0' }}
      allowFullScreen=""
    />
    <div className="informationContentsBox justify-content-around col-12 col-md-5 pl-2 pl-sm-3  pr-sm-0">
      <div className="informationContentsDetail  px-0 my-md-n3">
        {props.contact.map((value, index) =>
          value.alt !== '' ? (
            <div className="my-3 m-sm-3" key={value.contents + index}>
              <a
                href={value.href}
                target={
                  value.alt === 'location' || value.alt === 'parking'
                    ? '_blank'
                    : ''
                }
                rel="noopener noreferrer"
                className="contentsColorPrimary"
              >
                <div className="d-inline-block " style={{ width: '20px' }}>
                  <img
                    src={value.icon}
                    className={'align-self-center'}
                    alt={value.alt}
                  />
                </div>
                <small
                  className={'m-0 pl-2 pl-sm-3 '}
                  style={{ verticalAlign: 'middle' }}
                >
                  {value.contents}
                </small>
              </a>
            </div>
          ) : (
            <div className="m-3" key={value + index}>
              <NavLink
                to={value.href}
                className="contentsColorPrimary"
                key={value + index}
              >
                <img
                  src={value.icon}
                  className={'align-self-center'}
                  alt={value.alt}
                />
                <small className={'m-0 pl-3'}>{value.contents}</small>
              </NavLink>
            </div>
          )
        )}
      </div>
      <div className="informationContentsParking px-0 mt-3 mt-md-5 pt-md-5">
        <small className="m-0 m-md-3">若有停車需求，可至以下鄰近停車場</small>

        {props.parking.map((value, index) => (
          <div className=" my-3 m-md-3  " key={value + index}>
            <a
              href={value.href}
              target={'_blank'}
              rel="noopener noreferrer"
              className="contentsColorPrimary"
              key={value + index}
            >
              <small className="contentsColorGrey ml-1 mr-3">•</small>
              <small className="contentsColorPrimary">{value.title}</small>
            </a>
          </div>
        ))}
      </div>
    </div>
  </div>
)
export default Information
