import React from 'react'

const DivideLine = props => {
  const { text, className, style } = props

  return (
    <div
      className={`divideLine containerColorLightGrey position-relative mx-auto ${className}`}
      style={style}
    >
      <div className="divideLineBox bg-white position-absolute">
        {props.text ? (
          <h4 className="divideLineText m-0 contentsColorPrimary">{text}</h4>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default DivideLine
