import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import WalkAnimalRoute from './route/WalkAnimalRoute'
import rootReducer from './reducers/index'
import MediaQueries from './window/mediaQueries/MediaQueries'
// REDUX_DEVTOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// function createStore(reducer, preloadedState, enhancer) {}
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const App = () => {
  return (
    <Provider store={store}>
      <MediaQueries>
        <div className="wrapper">
          <WalkAnimalRoute />
        </div>
      </MediaQueries>
    </Provider>
  )
}

export default App
