import React, { useEffect, useState } from 'react'

// Data
//// time
import schedules from '../data/time'
import timeSlots from '../data/timeSlots'
//// team
import teamData from '../data/team'
import MobileTitle from '../components/share/MobileTitle'

// Component
import DivideLine from '../components/share/DivideLine'

// variables
//// schedule

const dateText = {
  day: [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ],
  chineseDay: ['日', '一', '二', '三', '四', '五', '六'],
  dayIndex: [0, 1, 2, 3, 4, 5, 6],
  chineseMonth: [
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '十一',
    '十二',
  ],
}
let expertise = {}
//// current day
let toDay = new Date().getDay()
//// color
const color = {
  container: [
    'containerColorPrimary',
    'containerColorSecondary',
    'containerColorPurple',
    'containerColorSuccess',
    'containerColorDanger',
    'containerColorBrown',
    'containerColorMidnightBlue',
    'containerColorGrey',
  ],
  border: [
    'containerBorderPrimary',
    'containerBorderSecondary',
    'containerBorderPurple',
    'containerBorderSuccess',
    'containerBorderDanger',
    'containerBorderBrown',
    'containerBorderMidnightBlue',
    'containerBorderGrey',
  ],
  contents: [
    'contentsColorPrimary',
    'contentsColorSecondary',
    'contentsColorPurple',
    'contentsColorSuccess',
    'contentsColorDanger',
    'contentsColorBrown',
    'contentsColorMidnightBlue',
    'contentsColorGrey',
  ],
}

// helper
const scheduleFormat = (value, vetState, vetName, day, dayState, index) => {
  if (dayState === 'all') {
    if (value === '0') {
      return ''
    }
    if (value === '1') {
      return (
        <div
          className={
            'mx-auto align-self-center ' +
            (vetState === 'all'
              ? ` ${color.container[index]}`
              : vetState === vetName
              ? ' containerColorPrimary'
              : ' containerColorLighterGray')
          }
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '100%',
            transition: '0.3s',
          }}
        ></div>
      )
    }
    if (value !== '0' && value !== '1') {
      return (
        <div className="d-flex flex-column timeFormWorkHalf position-relative">
          <div
            className={
              'align-self-end mx-auto  position-relative  ' +
              (vetState === 'all'
                ? `${color.container[index]}`
                : vetState === vetName
                ? ' containerColorPrimary'
                : ' containerColorLighterGray')
            }
            style={{
              width: '15px',
              height: '7.5px',
              borderRadius: '15px 15px 0 0',
              transform: 'rotate(-50deg)',
              transformOrigin: '0% 50%',
              transition: '0.3s',
            }}
          >
            <div
              className={
                'mx-auto  position-relative ' +
                (vetState === 'all'
                  ? ` ${color.border[index]}`
                  : vetState === vetName
                  ? ' containerBorderPrimary'
                  : ' containerBorderLighterGray')
              }
              style={{
                top: '0',
                left: '0',
                width: '15px',
                height: '15px',
                borderRadius: '15px',
                borderWidth: '1px',
                borderStyle: 'solid',
                transition: '0.3s',
              }}
            ></div>
          </div>
          <h6
            className={
              ' timeFormWorkHalfTime ' +
              (vetState === 'all'
                ? ` ${color.contents[index]}`
                : vetState === vetName
                ? ' contentsColorPrimary'
                : ' contentsColorLighterGray')
            }
            style={{ marginTop: '0.2rem', transition: '0.3s' }}
          >
            <small>(</small>
            {value}
            <small>)</small>
          </h6>
        </div>
      )
    }
  } else {
    if (value === '0') {
      return ''
    }
    if (value === '1') {
      return (
        <div
          className={
            'm-auto  position-relative align-self-center ' +
            (dayState === day
              ? ' containerColorPrimary'
              : ' containerColorLighterGray')
          }
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '100%',
            transition: '0.3s',
          }}
        ></div>
      )
    }
    if (value !== '0' && value !== '1') {
      return (
        <div className="d-flex flex-column timeFormWorkHalf">
          <div
            className={
              'align-self-end mx-auto position-relative ' +
              (dayState === day
                ? ' containerColorPrimary'
                : ' containerColorLighterGray')
            }
            style={{
              width: '15px',
              height: '7.5px',
              borderRadius: '15px 15px 0 0',
              transform: 'rotate(-50deg)',
              transformOrigin: '0% 50%',
              transition: '0.3s',
            }}
          >
            <div
              className={
                'm-auto  position-relative ' +
                (dayState === day
                  ? ' containerBorderPrimary'
                  : ' containerBorderLighterGray')
              }
              style={{
                top: '0',
                left: '0',
                width: '15px',
                height: '15px',
                borderRadius: '15px',
                borderWidth: '1px',
                borderStyle: 'solid',
                transition: '0.3s',
              }}
            ></div>
          </div>
          <h6
            className={
              'timeFormWorkHalfTime ' +
              (dayState === day
                ? ' contentsColorPrimary'
                : ' contentsColorLighterGray')
            }
            style={{ marginTop: '0.2rem', transition: '0.3s' }}
          >
            <small>(</small>
            {value}
            <small>)</small>
          </h6>
        </div>
      )
    }
  }
}

const expertiseFormat = obj => {
  expertise[obj.name] = obj.expertise
}

// format
teamData.forEach(value => {
  expertiseFormat(value)
})

// protect

const Time = props => {
  const { vetName = 'all' } = props.match.params
  useEffect(() => {
    window.scrollTo({ top: 0 })
    return () => {}
  }, [])
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth())
  const [vet, setVet] = useState(vetName)
  const [day, setDay] = useState('all')
  const defaultScheduleData = schedules.find(
    schedule => schedule.monthIndex === -1
  )
  const scheduleData = schedules.find(
    schedule => schedule.monthIndex === monthIndex || schedule.monthIndex === -1
  )
  const schedule = Array.isArray(scheduleData.schedule)
    ? scheduleData.schedule
    : Array.isArray(defaultScheduleData.schedule)
    ? defaultScheduleData.schedule
    : []
  const changeList = Array.isArray(scheduleData.changes)
    ? scheduleData.changes
    : []
  const holidayList = Array.isArray(scheduleData.holidays)
    ? scheduleData.holidays
    : []
  const changeHasLinkList = changeList.filter(change => change.link)

  const changeLinkedList = changeList.filter(change =>
    changeHasLinkList.find(changeHasLink => changeHasLink.link === change.id)
  )
  const changeNotLinkedList = changeList.filter(
    change =>
      !changeLinkedList.find(changeLinked => changeLinked.id === change.id)
  )

  const setVetClick = name => {
    setDay('all')
    if (vet !== name) {
      setVet(name)
    }
    if (vet === name) {
      setVet('all')
    }
  }
  const setDayClick = value => {
    setVet('all')
    if (day !== value) {
      setDay(value)
    }
    if (day === value) {
      setDay('all')
    }
  }

  return (
    <>
      <MobileTitle
        renderText={() => (
          <span className="h4 position-relative">
            {dateText.chineseMonth[monthIndex] + '月門診班表'}
            {monthIndex === new Date().getMonth() ? (
              <div
                className="position-absolute"
                style={{ cursor: 'pointer', top: '-3px', right: '-30px' }}
                onClick={() => {
                  setMonthIndex(
                    new Date().getMonth() === 11 ? 0 : monthIndex + 1
                  )
                }}
              >
                <img src="/assets/icon/arrowRight.svg" alt="" />
              </div>
            ) : (
              <></>
            )}
            {monthIndex !== new Date().getMonth() ? (
              <div
                className="position-absolute"
                style={{ cursor: 'pointer', top: '-3px', left: '-30px' }}
                onClick={() => {
                  setMonthIndex(new Date().getMonth())
                }}
              >
                <img src="/assets/icon/arrowLeft.svg" alt="" />
              </div>
            ) : (
              <></>
            )}
          </span>
        )}
        className={'mt-4'}
      />
      <div className="timeV2">
        <h3 className="timeTitle text-center contentsColorPrimary mb-1 mb-md-2 mb-xl-3">
          <span className="position-relative">
            {dateText.chineseMonth[monthIndex]}月
            {monthIndex === new Date().getMonth() ? (
              <div
                className="position-absolute"
                style={{ cursor: 'pointer', top: '-3px', left: '130px' }}
                onClick={() => {
                  setMonthIndex(
                    new Date().getMonth() === 11 ? 0 : monthIndex + 1
                  )
                }}
              >
                <img src="/assets/icon/arrowRight.svg" alt="" />
              </div>
            ) : (
              <></>
            )}
            {monthIndex !== new Date().getMonth() ? (
              <div
                className="position-absolute"
                style={{ cursor: 'pointer', top: '-3px', right: '130px' }}
                onClick={() => {
                  setMonthIndex(new Date().getMonth())
                }}
              >
                <img src="/assets/icon/arrowLeft.svg" alt="" />
              </div>
            ) : (
              <></>
            )}
          </span>
        </h3>
        <div className="timeBox">
          <div className="timeDay d-flex justify-content-center text-center">
            <div className="col-xl-1 d-flex px-0 justify-content-center mt-2 mb-2 mb-sm-4">
              {/*<h5 className="contentsColorGrey">獸醫師</h5>*/}
              {/*<p className="contentsColorGrey">(科別)</p>*/}
            </div>
            {dateText.chineseDay.map((value, index) => (
              <div
                className={
                  'timeDayTime col-xl-1 px-0 mt-2 mb-2 mb-sm-4 position-relative ' +
                  (day === 'all'
                    ? ' contentsColorGrey'
                    : day === value
                    ? ' contentsColorPrimary'
                    : ' contentsColorLighterGray')
                }
                onClick={() => setDayClick(value)}
                key={value + index}
              >
                <h5>{value}</h5>
                {dateText['dayIndex'][index] === toDay ? (
                  <img
                    width={'22px'}
                    className="position-absolute"
                    src="/assets/icon/paw1.svg"
                    alt="today"
                    style={{
                      top: 0,
                      left: '30%',
                      transform: 'rotate(30deg)',
                      opacity: '0.5',
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
          <div className="timeBoxScroll position-relative">
            <div className="timeForm">
              {schedule.map((value, index) => (
                <div
                  className="timeFormLine d-flex justify-content-center contentsColorGrey text-center position-relative"
                  key={value + index}
                >
                  <div
                    className="timeFormVet col-xl-1 px-0 d-flex justify-content-center my-4"
                    style={{ borderBottom: '1px solid #cccccc' }}
                    onClick={() => setVetClick(value.name)}
                  >
                    {day === 'all' ? (
                      <>
                        <p
                          className={
                            'h5 mr-xl-1' +
                            (vet === 'all'
                              ? ' contentsColorGrey'
                              : vet === value.name
                              ? ' contentsColorPrimary'
                              : ' contentsColorLighterGray')
                          }
                          style={{ transition: '0.3s' }}
                        >
                          {value.name}
                        </p>
                        <p
                          className={
                            vet === 'all'
                              ? ' contentsColorGrey'
                              : vet === value.name
                              ? ' contentsColorGrey'
                              : ' contentsColorLighterGray'
                          }
                          style={{ transition: '0.3s' }}
                        >
                          <small>(</small>
                          {value.specialist}
                          <small>)</small>
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          className={
                            'h5 mr-xl-1' +
                            (vet === 'all'
                              ? ' contentsColorGrey'
                              : vet === value.name
                              ? ' contentsColorPrimary'
                              : ' contentsColorLighterGray')
                          }
                          style={{ transition: '0.3s' }}
                        >
                          {value.name}
                        </p>
                        <p
                          className={
                            vet === 'all'
                              ? ' contentsColorGrey'
                              : vet === value.name
                              ? ' contentsColorGrey'
                              : ' contentsColorLighterGray'
                          }
                          style={{ transition: '0.3s' }}
                        >
                          <small>(</small>
                          {value.specialist}
                          <small>)</small>
                        </p>
                      </>
                    )}
                  </div>
                  {dateText.day.map((v, i) => (
                    <div
                      className="timeFormWork col-xl-1 px-0 my-4 d-flex justify-content-center "
                      style={{ borderBottom: '1px solid #cccccc' }}
                      key={v + i}
                    >
                      {scheduleFormat(
                        value[v],
                        vet,
                        value.name,
                        dateText.chineseDay[i],
                        day,
                        index
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {vet === 'all' ? (
              <></>
            ) : (
              !!expertise[vet] && (
                <div className="timeVetCard position-absolute bg-white p-md-2 p-lg-3 containerDisplaySlow">
                  <figure className="">
                    <img src={`/assets/images/team/${vet}.jpg`} alt={vet} />
                  </figure>
                  <h6>專長</h6>
                  <p className="p6 mb-0">{expertise[vet].join('、')}</p>
                </div>
              )
            )}
          </div>
          <div className="timeFooter">
            <div className="px-0 px-xl-2 col-xl-8 mx-auto timeFooterBox d-flex flex-wrap justify-content-center  my-3  ">
              <div className="timeFooterTime d-flex px-0 col-12 col-lg-8 contentsColorGrey align-content-center flex-wrap">
                <div className="timeFooterTimeWork d-flex col-12 col-sm-8 px-0">
                  <div
                    className="footerTimeWorkCircle containerColorPrimary align-self-center mr-2"
                    style={{
                      marginLeft: '1px',
                      width: '18px',
                      height: '18px',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <p
                    className="align-self-center mb-2 mb-md-0"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    早上 10：30 ～ 12：30{'\n'}
                    下午 13：30 ～ 17：00{'\n'}
                    晚上 18：00 ～ 21：00
                  </p>
                </div>
                <div className="timeFooterTimeHalf px-0 col-12 col-sm-4 d-flex align-content-center">
                  <h4 className="mb-0 contentsColorPrimary align-self-center mr-2">
                    ◐
                  </h4>
                  <p className="mb-0 align-self-center">看診前請先來電預約</p>
                </div>
              </div>
              <div className="timeFooterHint mt-2 mt-sm-3 mt-lg-0 px-0 d-flex col-12 col-lg-4 justify-content-center align-items-center  contentsColorPrimary">
                {/*<img*/}
                {/*  className="ml-3  mr-2 align-self-center"*/}
                {/*  src="/assets/icon/note.svg"*/}
                {/*  alt=""*/}
                {/*/>*/}
                <small className="p4">※</small>
                <p className="p5 mb-0 align-self-center">
                  適用於每週，每天都有門診
                </p>
              </div>
            </div>
          </div>
          {(Boolean(changeList.length) || Boolean(holidayList.length)) && (
            <div className="timeChangeBlock mb-5">
              <DivideLine text={'門診異動'} />
              {Boolean(holidayList.length) && (
                <>
                  <div
                    className="contentsColorGrey timeChangeContainer d-flex flex-wrap px-lg-5"
                    style={{ margin: 'auto' }}
                  >
                    {holidayList.map((holiday, index) => {
                      const day = new Date(
                        `${new Date().getFullYear()}-${(monthIndex + 1)
                          .toString()
                          .padStart(2, 0)}-${holiday.date
                          ?.toString()
                          .padStart(2, 0)}`
                      ).getDay()
                      const holidayTime = `${monthIndex +
                        1}/${holiday.date.toString().padStart(2, 0)}${
                        day !== undefined
                          ? '（' + dateText.chineseDay[day] + '）'
                          : ''
                      }`
                      return (
                        <p
                          key={holiday.id ?? index}
                          className="col-6 col-lg-4 px-0 px-sm-3"
                        >
                          {holidayTime}整日休診
                        </p>
                      )
                    })}
                  </div>
                  <DivideLine className="containerColorLight" />
                </>
              )}
              <div
                className="contentsColorGrey timeChangeContainer d-flex flex-wrap px-lg-5"
                style={{ width: 'fit-content', margin: 'auto' }}
              >
                {changeNotLinkedList.map((change, index) => {
                  const hasLink = Boolean(change.link)
                  const day = new Date(
                    `${new Date().getFullYear()}-${(monthIndex + 1)
                      .toString()
                      .padStart(2, 0)}-${change.date
                      ?.toString()
                      .padStart(2, 0)}`
                  ).getDay()
                  const linkedChange = hasLink
                    ? changeLinkedList.find(
                        changeLinked => changeLinked.id === change.link
                      )
                    : {}
                  const linkedChangeDay = linkedChange.date
                    ? new Date(
                        `${new Date().getFullYear()}-${(monthIndex + 1)
                          .toString()
                          .padStart(
                            2,
                            0
                          )}-${linkedChange.date?.toString().padStart(2, 0)}`
                      ).getDay()
                    : null
                  const isLinkedBySameVet = hasLink
                    ? change.name === linkedChange.name
                    : false

                  const changeTime = `${monthIndex +
                    1}/${change.date.toString().padStart(2, 0)}${
                    day !== undefined
                      ? '（' + dateText.chineseDay[day] + '）'
                      : ''
                  }`

                  const sameVetChangeTime = isLinkedBySameVet
                    ? `${(
                        monthIndex + 1
                      ).toString()}/${linkedChange.date
                        .toString()
                        .padStart(2, 0)}${
                        linkedChangeDay !== undefined
                          ? '（' + dateText.chineseDay[linkedChangeDay] + '）'
                          : ''
                      }`
                    : ''

                  const changeTimeSlot = timeSlots.find(
                    timeSlot => timeSlot.id === change.timeSlotId
                  )

                  return (
                    <p
                      key={change.id ?? index}
                      className="col-12 col-lg-6 px-0 px-sm-3"
                    >{`${changeTime}${change.name}醫師${
                      isLinkedBySameVet
                        ? '門診改至 ' + sameVetChangeTime
                        : change.isWork
                        ? '加診'
                        : (changeTimeSlot
                            ? ` ${changeTimeSlot.startTime} ～ ${changeTimeSlot.endTime} `
                            : '') +
                          '休診' +
                          (hasLink
                            ? '，由' + linkedChange?.name + '醫師看診'
                            : '')
                    }`}</p>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Time
