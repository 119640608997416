import React from 'react'
import Slider from 'react-slick'
import { isMobile } from 'react-device-detect'

// images
const imageSrcPc = ['team1@2x']
const imageSrcMobile = ['team1@1x']


class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }
  slickGo = index => {
    this.slider.slickGoTo(index)
  }

  deviceDetect = () => {
    if (isMobile) {
      return imageSrcMobile
    } else {
      return imageSrcPc
    }
  }

  render() {
    const setting = {
      infinite: true,
      // speed: 1500,
      draggable: false,
      // swipe:true,
      // easing: 'ease-out',
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      // autoplaySpeed: 4000,
      beforeChange: (current, next) => this.setState({ index: next }),
    }
    return (
      <>
        <figure className="aboutCarousel">
          <Slider ref={c => (this.slider = c)} {...setting}>
            {this.deviceDetect().map((value, index) => (
              <img
                src={`/assets/images/about/${value}.jpg`}
                alt={value}
                key={index + value}
              />
            ))}
          </Slider>
        </figure>
        <div className="aboutCarouselBar d-flex justify-content-center mt-n1">
          {/*{imageSrc.map((value, index) => (*/}
          {/*  <div*/}
          {/*    onClick={() => this.slickGo(index)}*/}
          {/*    className="aboutCarouselBarBox d-flex justify-content-center mx-2"*/}
          {/*  key={value+index}>*/}
          {/*    <div*/}
          {/*      className={*/}
          {/*        'align-self-center aboutCarouselBarPress ' +*/}
          {/*        (this.state.index === index*/}
          {/*          ? 'containerColorPrimary '*/}
          {/*          : 'containerColorLightGrey')*/}
          {/*      }*/}
          {/*    ></div>*/}
          {/*  </div>*/}
          {/*))}*/}
        </div>
      </>
    )
  }
}

export default Carousel
