import React from 'react'
import { NavLink } from 'react-router-dom'

const service = [
  {
    icon: '/assets/icon/stethoscope_c.svg',
    title: '內科',
    hash: 'internal',
  },
  {
    icon: '/assets/icon/scalpel_c.svg',
    title: '外科',
    hash: 'surgery',
  },
  {
    icon: '/assets/icon/xRay_c.svg',
    title: '影像科',
    hash: 'imaging',
  },
  {
    icon: '/assets/icon/bone_c.svg',
    title: '骨科',
    hash: 'orthopedics',
  },
  {
    icon: '/assets/icon/tooth_c.svg',
    title: '牙科',
    hash: 'dentistry',
  },
  {
    icon: '/assets/icon/crutch_c.svg',
    title: '跛行診斷',
    hash: 'claudication',
  },
  {
    icon: '/assets/icon/microscope_c.svg',
    title: '檢驗科',
    hash: 'laboratory',
  },
  {
    icon: '/assets/icon/needle_c.svg',
    title: '腫瘤科',
    hash: 'oncology',
  },
]

const Service = () => (
  <div className="homeService col-11 mx-auto d-flex justify-content-around flex-wrap px-4">
    {service.map((value, index) => (
      <NavLink
        to={'/service#' + value.hash}
        className="serviceBox col-6 col-sm-6 col-md-3 col-lg-1 col-xl-1 d-flex flex-column text-center px-0 px-xl-2  mb-lg-0"
        key={value + index}
      >
        <img className="align-self-center" src={value.icon} alt={value.title} />
        <h5>{value.title}</h5>
      </NavLink>
    ))}
  </div>
)

export default Service
