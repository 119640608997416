import React, { useState } from 'react'
import Alert from './Alert'

// fetch function
const submitForm = async formState => {
  const formData = {
    name: formState.name,
    phone: formState.phone,
    email: formState.email,
    subject: formState.subject,
    message: formState.message,
  }
  try {
    const response = await fetch('/.netlify/functions/sendmail', {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    })
    // if (!response.ok) throw new Error(response.statusText)
    if (!response.ok) {
      return false
    }
    const data = await response.json()
    console.log('200 response', data)
    return true
  } catch (e) {
    return false
  }
}

// component
const Question = props => {
  const [walkAlert, setWalkAlert] = useState(false)
  const [success, setSuccess] = useState(false)
  const [fail, setFail] = useState(false)
  const [submitN, setSubmitN] = useState(0)

  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '請選擇提問主題',
    message: '',
    nameInfo: '',
    phoneInfo: '',
    emailInfo: '',
    subjectInfo: '',
    messageInfo: '',
  })
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const inputItemBlank = {
    name: '',
    phone: '',
    email: '',
    subject: '請選擇提問主題',
    message: '',
  }
  const setBlank = () => {
    setFormState({ ...formState, ...inputItemBlank })
  }
  const formFetch = async () => {
    console.log('formFetch')
    if (submitN === 0) {
      await setSubmitN(submitN + 1)
      const response = await submitForm(formState)
      if (response) {
        setSuccess(true)
      }
      if (!response) {
        setFail(true)
      }
    } else {
      alert('請勿在短時間內發送過多次，如有更多疑問請電洽沃可')
    }
  }
  const formSubmit = async () => {
    const result = await submitCheck()
    if (result === 0) {
      console.log('表單填寫成功')
      await setWalkAlert(true)
    }
    //   alert('此功能尚未開放，請致電 ( 04 ) 2236 - 8936')
  }

  const submitCheck = () => {
    let infoItem = {},
      item,
      result = 0
    const required_fields = [
      {
        name: 'name',
        pattern: /^[\u4e00-\u9fa5_a-zA-Z]+$/,
        info: '不含符號與空格',
      },
      {
        name: 'phone',
        pattern: /^09\d{2}-?\d{3}-?\d{3}$/,
        info: '10個數字',
      },
      {
        name: 'email',
        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        info: '輸入正確的email格式',
      },
      {
        name: 'subject',
        pattern: /^(?!(請選擇提問主題)$)/,
        info: '請選擇',
      },
      {
        name: 'message',
        pattern: /./,
        info: '請輸入想要提問的內容',
      },
    ]

    for (item of required_fields) {
      if (!item.pattern.test(formState[item.name])) {
        infoItem[item.name + 'Info'] = item.info
        result += 1
      } else {
        infoItem[item.name + 'Info'] = ''
      }
    }
    setFormState({ ...formState, ...infoItem })
    return result
  }
  // passing alert function
  const setState = {
    setWalkAlert,
    setSuccess,
    setFail,
    setBlank,
  }
  return (
    <>
      {walkAlert ? (
        <Alert
          formFetch={formFetch}
          setState={setState}
          state={walkAlert}
          success={success}
          fail={fail}
          data={formState}
        />
      ) : (
        <></>
      )}
      <div className="question">
        <div className="questionInputBox col-12 col-md-11 col-md-10 col-xl-8 m-auto d-flex flex-wrap justify-content-center">
          <div className="questionInput col-12 col-sm-6 px-0  px-md-3">
            <div className=" d-flex justify-content-start flex-wrap mb-5">
              <div className="questionInputName col-6 position-relative">
                <h5 className="contentsColorGreyer">
                  姓名<small className="contentsColorDanger">*</small>
                </h5>
                <input
                  onChange={onChange}
                  type="text"
                  placeholder="沃可"
                  value={formState.name}
                  name="name"
                  maxLength={8}
                />
                <small className="contentsColorDanger questionInputInfo">
                  {formState.nameInfo}
                </small>
              </div>
              <div className="questionInputPhone col-6 position-relative">
                <h5 className="contentsColorGreyer">
                  手機<small className="contentsColorDanger">*</small>
                </h5>
                <input
                  onChange={onChange}
                  type="text"
                  placeholder="0900-111-222"
                  name="phone"
                  value={formState.phone}
                  maxLength={12}
                />
                <small className="contentsColorDanger questionInputInfo">
                  {formState.phoneInfo}
                </small>
              </div>
            </div>
            <div className="questionInputEmail col-12 mb-5 position-relative">
              <h5 className="contentsColorGreyer">
                Email<small className="contentsColorDanger">*</small>
              </h5>
              <input
                onChange={onChange}
                type="email"
                name="email"
                placeholder="walkwalkah@gmail.com"
                value={formState.email}
              />
              <small className="contentsColorDanger questionInputInfo">
                {formState.emailInfo}
              </small>
            </div>
            <div className="questionInputTopic col-12 mb-5 position-relative">
              <h5 className="contentsColorGreyer">
                主題<small className="contentsColorDanger">*</small>
              </h5>
              <select
                name="subject"
                value={formState.subject}
                onChange={onChange}
              >
                <option value="請選擇提問主題">請選擇提問主題</option>
                {/*<option value="我家毛小孩有異狀">我家毛小孩有異狀</option>*/}
                <option value="對於沃可有些疑問">對於沃可有些疑問或建議</option>
                <option value="對於這網站有點疑問或建議">
                  對於此網站有點疑問或建議
                </option>
                <option value="想詢問關於手術或其它診療的事情">
                  想詢問關於手術或其它診療的事情
                </option>
                <option value="其它">其它</option>
              </select>
              <small className="contentsColorDanger questionInputInfo">
                {formState.subjectInfo}
              </small>
            </div>
          </div>
          <div className="questionTextarea col-12 col-sm-6 mb-4 mb-sm-0 position-relative">
            <h5 className="contentsColorGreyer">
              提問內容<small className="contentsColorDanger">*</small>
            </h5>
            <textarea
              onChange={onChange}
              placeholder="Ex. 請問我家的毛小孩....."
              value={formState.message}
              name="message"
              maxLength={310}
            ></textarea>
            <small className="contentsColorDanger textareaInfo">
              {formState.messageInfo}
            </small>
          </div>
        </div>
        <div className="questionButton d-flex justify-content-center mt-4">
          <div
            className="questionButtonClick d-flex justify-content-center align-items-center"
            onClick={formSubmit}
          >
            <h5 className="mb-0">送出</h5>
          </div>
        </div>
        <div className="pt-4 px-4 px-sm-0 contentsColorPrimary d-flex justify-content-center align-items-center">
          <small className="p4">※</small>
          <p className="mb-0">
            送出之後，我們會盡快回覆您的問題，如有緊急請致電沃可
          </p>
        </div>
      </div>
    </>
  )
}
export default Question
