const timeSlots = [
  {
    id: 'b536a427-5a16-454d-bd90-6326b66d9f5e',
    name: 'morning',
    startTime: '10:30',
    endTime: '12:30',
  },
  {
    id: 'e4af7066-2db2-4b18-bd81-ad86d5c9e0e8',
    name: 'afternoon',
    startTime: '13:30',
    endTime: '17:00',
  },
  {
    id: '6a4089e2-a06e-4ff9-b85f-7c445c684812',
    name: 'evening',
    startTime: '18:00',
    endTime: '21:00',
  },
]

export default timeSlots
