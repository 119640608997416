import React, { useEffect } from 'react'

// Component
import Carousel from '../components/main/home/Carousel'
import Features from '../components/main/home/Features'
import Service from '../components/main/home/Service'
import DivideLine from '../components/share/DivideLine'

const Home = () => {
  useEffect(() => {
    // componentDidMount is here
    window.scrollTo({ top: 0 })
    return () => {
      // componentWillUnmount is here!
    }
  }, [])
  return (
    <div className="home pageBuffering">
      <Carousel />
      {/*<DivideLine*/}
      {/*  text={'最新消息'}*/}
      {/*  className={' mt-4'}*/}
      {/*  style={{ width: '90vw' }}*/}
      {/*/>*/}
      {/*<div className="d-flex justify-content-center">*/}
      {/*  <a*/}
      {/*    href="https://facebook.com/events/s/%E6%B2%83%E5%8F%AF%E5%8B%95%E7%89%A9%E9%86%AB%E9%99%A2%E9%96%8B%E5%B9%95%E8%8C%B6%E6%9C%83/220792809120982/?ti=icl"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    <img*/}
      {/*      className=""*/}
      {/*      style={{ width: '80vw' }}*/}
      {/*      src="/assets/images/home/inviteCard.jpg"*/}
      {/*      alt="開會茶會消息"*/}
      {/*    />*/}
      {/*  </a>*/}
      {/*</div>*/}
      <DivideLine
        text={'關於我們'}
        className={' mb-sm-5 mb-xl-4'}
        style={{ marginTop: '5vh', width: '90vw' }}
      />
      <Features />
      <DivideLine
        text={'診療服務'}
        className={'mb-5'}
        style={{ marginTop: '4vh', width: '90vw' }}
      />
      <Service />
      <iframe
        title="Walk Animal Hospital from Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4592.042223207294!2d120.6868877240335!3d24.171455146358173!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a7f97d0f557f64f!2z5rKD5Y-v5YuV54mp6Yar6Zmi!5e0!3m2!1szh-TW!2stw!4v1584260576546!5m2!1szh-TW!2stw"
        height="600"
        frameBorder="0"
        style={{ display: 'block', border: 0, width: '100vw' }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  )
}
export default Home
