export const breakpoints = {
  // desktopLg: 1400,
  // desktopMd: 1300,
  // desktopSm: 1200,
  TabletLg: 1040,
  // TabletMd: 991,
  TabletSm: 840,
  mobileLg: 768,
  mobileMd: 540,
  // mobileSm: 375,
}
