import React from 'react'
import { NavLink } from 'react-router-dom'
const features = [
  {
    icon: '/assets/icon/paw_c.svg',
    title: '醫療團隊',
    contents:
      '來自中興大學獸醫教學醫院的醫療團隊，各司其職，獸醫師也不間段的持續進修，確保毛孩子獲得全方位的專業醫療照護。',
    alt: 'team',
    routeLink: '/team',
  },
  /* {
    icon: '/assets/icon/briefcaseMedical.svg',
    title: '服務項目',
    contents:
      '我們為您的毛小孩提供專業的內科、外科、牙科、復健、腫瘤、影像等醫療照護，以及完整健康檢查與醫療設備，此外也提供狗貓區隔的候診區與診間，讓貓主子就診時更放鬆自在。',
  }, */
  {
    icon: '/assets/icon/heart_c.svg',
    title: '院內設備',
    contents:
      '我們在乎檢查的準確度與品質，備有IDEXX血檢機、X光機、超音波、氣體麻醉儀、生理監控儀與內視鏡等設備。',
    alt: 'equipment',
  },
  {
    icon: '/assets/icon/calendar_c.svg',
    title: '門診時刻',
    contents: '平日與假日門診時間一樣，提供晚間門診，讓您更方便帶寶貝來就診。',
    alt: 'work time',
    routeLink: '/time',
  },
]

const Features = () => (
  <>
    <div className="homeFeatures d-flex justify-content-around flex-wrap px-5">
      {features.map((value, index) =>
        value.routeLink ? (
          <NavLink
            className="col-9 col-sm-7 col-md-3 text-center d-flex flex-column p-0 p-lg-3 p-xl-5 mb-5 mb-md-0"
            key={value + index}
            to={value.routeLink}
          >
            <div className="featuresIconBox containerColorPrimary ">
              <figure className="featuresIcon bg-white d-inline-block">
                <img src={value.icon} alt={value.alt} />
              </figure>
            </div>
            <h5 className="my-4">{value.title}</h5>
            <p className="p5 contentsColorGrey  align-self-center">
              {value.contents}
            </p>
          </NavLink>
        ) : (
          <div
            className="col-9 col-sm-7 col-md-3 text-center d-flex flex-column p-0 p-lg-3 p-xl-5 mb-5 mb-md-0"
            key={value + index}
          >
            <div className="featuresIconBox containerColorPrimary ">
              <figure className="featuresIcon bg-white d-inline-block">
                <img src={value.icon} alt={value.alt} />
              </figure>
            </div>
            <h5 className="my-4">{value.title}</h5>
            <p className="p5 contentsColorGrey  align-self-center">
              {value.contents}
            </p>
          </div>
        )
      )}
    </div>
  </>
)

export default Features
