import React from 'react'
import Slider from 'react-slick'

// images
const imageSrc = ['hospital']

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }
  slickGo = index => {
    this.slider.slickGoTo(index)
  }
  render() {
    const setting = {
      infinite: true,
      speed: 1500,
      draggable: false,
      easing: 'ease-out',
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ index: next }),
    }

    return (
      <div className="serviceCarousel position-relative">
        <figure className="carouselFigure" >
          <Slider ref={c => (this.slider = c)} {...setting}>
            {imageSrc.map((value, index) => (
                <img
                    key={value+index}
                  src={`/assets/images/service/${imageSrc[index]}.jpg`}
                  alt=""
                />
            ))}
          </Slider>
        </figure>
      </div>
    )
  }
}

export default Carousel
