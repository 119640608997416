import React, { useEffect } from 'react'

import Carousel from '../components/main/service/Carousel'

// Service Data
import serviceData from '../data/service'
import MobileTitle from '../components/share/MobileTitle'

const Service = props => {
  let windowWidth = window.innerWidth
  useEffect(() => {
    let { hash } = props.location
    if (!hash) {
      window.scrollTo({ top: 0 })
    } else {
      const height = document.querySelector(hash).offsetTop + windowWidth / 3
      window.scrollTo({ top: height })
    }
    return () => {}
  }, [])
  return (
    <div className="pageBuffering service">
      <Carousel />
      <MobileTitle text={'診療服務'} className={'mt-n3 mb-2'} />
      <div className="serviceContentsBox col-12 col-md-10 mx-auto d-flex justify-content-center flex-wrap">
        {serviceData.map((value, index) => (
          <div
            className="serviceContentsContainer col-12 col-md-6 col-lg-4 d-flex justify-content-center"
            key={value + index}
            id={value.id}
          >
            <div className="serviceContents col-11 col-sm-10 col-md-12  col-xl-11 text-center d-flex flex-column">
              <img className="mx-auto" src={value.icon} alt={value.title} />
              {/*<div className="serviceContentsLine containerColorPrimary align-self-center"></div>*/}
              <h5 className="serviceContentsTitle mt-4">{value.title}</h5>
              <p className="serviceContentsIntro contentsColorGrey">
                {value.contents}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Service
