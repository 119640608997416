import React from 'react'
import Slider from 'react-slick'
import { isMobile } from 'react-device-detect'

// images
// const imageSrcPc = ['cover3@pc', 'yuki@pc', 'walkcover@pc']
// const imageSrcMobile = ['cover3@mobile', 'yuki@mobile', 'walkcover@mobile']
const imageSrcPc = ['uber', 'dog3', 'dog2', 'yuki@pc', 'dog4']
const imageSrcMobile = [
  'uber@mobile',
  'dog3@mobile',
  'dog2@mobile',
  'yuki@mobile',
  'dog4@mobile',
]

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }
  slickGo = index => {
    this.slider.slickGoTo(index)
  }
  deviceDetect = () => {
    if (isMobile) {
      return imageSrcMobile
    } else {
      return imageSrcPc
    }
  }
  render() {
    const setting = {
      adaptiveHeight: true,
      infinite: true,
      speed: 1500,
      draggable: false,
      swipe: true,
      easing: 'ease-out',
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      beforeChange: (current, next) => this.setState({ index: next }),
    }

    return (
      <div className="homeCarousel position-relative">
        <figure className="carouselFigure">
          <Slider ref={c => (this.slider = c)} {...setting}>
            {this.deviceDetect().map((value, index) => (
              <img
                key={value + index}
                src={`/assets/images/home/${value}.jpg`}
                alt={value}
              />
            ))}
          </Slider>
        </figure>
        <div className="carouselContents position-absolute text-white">
          <p className="h2 title1">給每位犬貓最好的醫療照護</p>
          <p className="h5 title2">
            可以跟以前一樣陪伴飼主散步、在草地上跑跳翻滾，{'\n'}
            是我們成立沃可的初衷。
          </p>
        </div>
        <div
          className="carouselBars d-flex justify-content-center mt-n1"
          style={{ transform: 'translateY(-50px)' }}
        >
          {this.deviceDetect().map((value, index) => (
            <div
              onClick={() => this.slickGo(index)}
              className="carouselBarBox d-flex mx-2"
              key={value + index}
            >
              <div
                className={
                  this.state.index === index
                    ? 'carouselBar containerColorPrimary align-self-center'
                    : 'carouselBar containerColorLightGrey align-self-center'
                }
              ></div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Carousel
