import {
  SCREEN_SCROLL,
  SCREEN_RESIZE,
  SET_ACTIVE_BREAKPOINT,
  MOBILE_SHOW_NAVS,
} from '../index'

// Action Creators
//// window
////// screenResize
/*
 * @param {number} payload
 * @return {Object} Action object
 */
export const screenResize = payload => ({
  type: SCREEN_RESIZE,
  payload,
})
////// screenScroll
/*
 * @param {number} payload
 * @return {Object} Action object
 */
export const screenScroll = payload => ({
  type: SCREEN_SCROLL,
  payload,
})
////// setActiveBreakpoint
/*
 * @param {string} breakpointName
 * @param {number} breakpointSize
 * @return {Object} Action object
 */
export const setActiveBreakpoint = (breakpointName, breakpointSize) => ({
  type: SET_ACTIVE_BREAKPOINT,
  breakpointName,
  breakpointSize,
})

////  component
////// mobile
export const mobileShowNavs = payload => ({
  type: MOBILE_SHOW_NAVS,
  title: 'Navs',
  payload,
})
////// web
