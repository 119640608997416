import React from 'react'
import { NavLink } from 'react-router-dom'
const contact = [
  {
    icon: '/assets/icon/email.svg',
    contents: 'walkwalkah@gmail.com',
    alt: 'email',
    href: 'mailto:walkwalkah@gmail.com',
  },
  {
    icon: '/assets/icon/phone.svg',
    contents: '( 04 ) 2236 - 8936',
    alt: 'phone',
    href: 'tel:( 04 ) 2236 - 8936',
  },
  {
    icon: '/assets/icon/marker.svg',
    contents: '40651 台中市北屯區文心路四段776號',
    alt: 'location',
    href: 'https://goo.gl/maps/XqaPeRuyJnThSQKm7',
  },
]

const times = [
  {
    icon: '/assets/icon/clock.svg',
    contents: '早上 10:30 - 12:30、下午 13:30 - 17:00',
    alt: 'time',
    href: '/time',
  },
  {
    icon: '/assets/icon/clock.svg',
    contents: '晚上 18:00 - 21:00',
    alt: 'time',
    href: '/time',
  },
]
const contact2 = [
  {
    icon: '/assets/icon/facebook.svg',
    contents: '沃可動物醫院',
    alt: 'facebook',
    href: 'https://www.facebook.com/walkvet/',
  },
]

const Footer = () => (
  <div
    className="footer d-flex justify-content-around flex-wrap pb-3"
    style={{ background: '#f8f8f8' }}
  >
    <NavLink
      to={'/'}
      className="footerLogo col-8 col-sm-6 col-md-4 col-lg-4 align-self-center "
    >
      <img
        style={{ width: '100%' }}
        src="/assets/icon/LogoTextGreen.svg"
        alt="LogoText"
      />
    </NavLink>
    <div className="footerText col-sm-6 col-md-5 col-lg-4 col-xl-4 align-self-center px-0 px-md-3 px-lg-4 mt-n3 mt-sm-0 py-sm-2 py-xl-0">
      {contact.map((value, index) => (
        <div className="my-3" key={value + index}>
          <a
            href={value.href}
            target={value.alt === 'location' ? '_blank' : ''}
            rel="noopener noreferrer"
            className="d-flex justify-content-start align-items-center  contentsColorPrimary"
          >
            <img src={value.icon} alt={value.alt} />
            <small className={'m-0 pl-3'}>{value.contents}</small>
          </a>
        </div>
      ))}
      {times.map(time => (
        <div key={time.contents} className="my-3">
          <NavLink
            key={time.contents}
            to={time.href}
            className=" contentsColorPrimary"
          >
            <img
              src={time.icon}
              className={'align-self-center'}
              alt={time.alt}
            />
            <small className="m-0 pl-3">{time.contents}</small>
          </NavLink>
        </div>
      ))}
      {contact2.map((value, index) => (
        <div className="my-3" key={value + index}>
          <a
            href={value.href}
            target={'_blank'}
            rel="noopener noreferrer"
            className="d-flex justify-content-start align-items-center contentsColorPrimary"
          >
            <div className="d-inline-block " style={{ width: '32px' }}>
              <img src={value.icon} alt={value.alt} />
            </div>

            <small className={'m-0 '}>{value.contents}</small>
          </a>
        </div>
      ))}
    </div>
    <img
      className="footerMap col-6 col-md-3 col-lg-3 align-self-center"
      src="/assets/icon/mapGreen.svg"
      alt="Hospital Map"
    />
    <div className="col-12 contentsColorGrey text-center copyrightText">
      Copyright © 2020 沃可動物醫院 <small>／</small>
      {'\n'} Design by LCH{' '}
    </div>
  </div>
)

export default Footer
