import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Team Data
import teamData from '../data/team'
import DivideLine from '../components/share/DivideLine'
import MobileTitle from '../components/share/MobileTitle'

const Team = () => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo({ top: 0 })
    return () => {}
  }, [])
  return (
    <>
      <MobileTitle text={'醫療團隊'} className={'mt-4 mt-sm-5'} />
      <div className="pageBuffering team px-0 px-md-5">
        {teamData.map((value, index, array) => (
          <React.Fragment key={value + index}>
            <div
              className="teamBox d-flex justify-content-center flex-wrap"
              style={
                index !== array.length - 1
                  ? { marginBottom: '0px' }
                  : { marginBottom: '50px' }
              }
            >
              <div className="teamFigure col-10 col-md-4 col-lg-3 col-xl-3 mr-0 mr-md-3 mr-lg-5">
                <figure className="mb-0 m-auto">
                  <img src={value.image} alt={value.name} />
                </figure>
              </div>
              <div className="teamDetail col-12 col-md-7 col-lg-6 col-xl-6 p-0 p-sm-3">
                <div className="d-flex justify-content-start mb-3 teamTitle mt-3 mt-md-0">
                  <p
                    style={{
                      borderBottom: '2px solid #74A19C',
                    }}
                    className="teamName h4 mr-3 contentsColorGreyer"
                    onClick={() => history.push(`/time/${value.name}`)}
                  >
                    {value.name}
                  </p>
                  <p className="p5 contentsColorGrey">{value.title}</p>
                </div>
                <div className="d-flex justify-content-start flex-wrap teamContents">
                  <div className="col-11 col-sm-11 col-md-8 col-lg-8 col-xl-7  p-0 p-lg-0  contentsLayoutPreWrap mb-4 mb-sm-3 ">
                    <p className="p5 contentsColorGreyer mb-1 mb-sm-3">
                      經歷：
                    </p>
                    <p
                      className="p5 contentsColorGrey"
                      style={{ lineHeight: '2.25rem' }}
                    >
                      {value.experience.join('\n')}
                    </p>
                  </div>
                  <div className="col-9 col-sm-6 col-md-4 col-lg-4 col-xl-5 pl-3 p-lg-0 mb-0 mb-sm-3">
                    <p className="p5 contentsColorGreyer mb-1 mb-sm-3">
                      專長：
                    </p>
                    <p
                      className="p5 contentsColorGrey mb-0 mb-sm-3"
                      style={{ lineHeight: '2.25rem' }}
                    >
                      {value.expertise.join('、')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {array.length - 1 !== index ? (
              <DivideLine style={{ width: '70vw' }} />
            ) : (
              ''
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
export default Team
